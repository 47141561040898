<template>
	<v-main>
		<slot />
	</v-main>
</template>

<script>
export default {
	name: "AppLayoutGuest",
};
</script>
